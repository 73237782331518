const url = encodeURIComponent('https://graph.facebook.com/?id=https://1000000.leader-id.ru/&fields=og_object{engagement}'.split('#')[0]);


$.ajax({
  url: 'https://graph.facebook.com/?id=https://1000000.leader-id.ru/&fields=og_object{engagement}',
  dataType: 'jsonp',
  timeout: 5000,
  success: function (obj) {
    let count = 0;

    try {
      count = obj.og_object.engagement.count
    } catch (e) {
      console.log(e)
    }
    $('.share__link--fb .share__link-content').text(count);
  },
  error: function () {
    console.log('error');
  }
});
