var controller = new ScrollMagic.Controller();

var picture1tween = TweenMax.to('.picture__main--picture1 .picture__part', 1, {
  transform: 'translateY(-160px)',
  opacity: '1',
  ease: Linear.easeNone
});

var picture1scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture1 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '30%'
})
.setTween(picture1tween)
.addTo(controller)


var picture2tween = TweenMax.to('.picture__main--picture2 .picture__part', 1, {
  transform: 'translateX(-115px)',
  opacity: '1',
  ease: Linear.easeNone
});

var picture2scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture2 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween(picture2tween)
.addTo(controller)


var picture3tween1 = TweenMax.to('.picture__main--picture3 .picture__part--1', 1, {
  transform: 'translateX(67px)',
  opacity: '1',
  ease: Linear.easeNone
});

var picture3scene1 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture3",
  triggerHook: "1",
  reverse: true,
  duration: '50%'
})
.setTween(picture3tween1)
.addTo(controller)


var picture3tween2 = TweenMax.to('.picture__main--picture3 .picture__part--2', 1, {
  transform: 'translateX(67px)',
  opacity: '1',
  ease: Linear.easeNone
});

var picture3scene2 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture3",
  triggerHook: "0.9",
  reverse: true,
  duration: '50%'
})
.setTween(picture3tween2)
.addTo(controller)


var picture3tween3 = TweenMax.to('.picture__main--picture3 .picture__part--3', 1, {
  transform: 'translateX(67px)',
  opacity: '1',
  ease: Linear.easeNone
});

var picture3scene3 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture3",
  triggerHook: "0.8",
  reverse: true,
  duration: '50%'
})
.setTween(picture3tween3)
.addTo(controller)



var picture4tween1 = TweenMax.to('.picture__main--picture4 .picture__part--1', 1, {
  transform: 'translateY(160px)',
  ease: Linear.easeNone
});

var picture4scene1 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture4",
  triggerHook: ".8",
  reverse: true,
  duration: '30%'
})
.setTween(picture4tween1)
.addTo(controller)


var picture4tween2 = TweenMax.to('.picture__main--picture4 .picture__part--2', 1, {
  transform: 'translateY(320px)',
  ease: Linear.easeNone
});

var picture4scene2 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture4",
  triggerHook: ".8",
  reverse: true,
  duration: '30%'
})
.setTween(picture4tween2)
.addTo(controller)


var picture4tween3 = TweenMax.to('.picture__main--picture4 .picture__part--3', 1, {
  transform: 'translateY(250px)',
  ease: Linear.easeNone
});

var picture4scene3 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture4",
  triggerHook: ".8",
  reverse: true,
  duration: '30%'
})
.setTween(picture4tween3)
.addTo(controller)


var picture5tween = TweenMax.to('.picture__main--picture5 .picture__part', 1, {
  transform: 'translateY(-179px)',
  opacity: '1',
  ease: Linear.easeNone
});

var picture5scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture5 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween(picture5tween)
.addTo(controller)


var picture6scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture6 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture6 .picture__part--1', {x: '35px', y: '35px'})
.addTo(controller)


var picture6scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture6 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture6 .picture__part--2', {x: '-80px', y: '10px'})
.addTo(controller)


var picture6scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture6 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture6 .picture__part--3', {x: '40px', y: '-35px'})
.addTo(controller)


var picture6scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture6 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture6 .picture__part--4', {x: '-40px', y: '35px'})
.addTo(controller)


var picture6scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture6 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture6 .picture__part--5', {x: '-35px', y: '-30px'})
.addTo(controller)


var picture8scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture8 .picture__part",
  triggerHook: ".5",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture8 .picture__part', {top: '-15px'})
.addTo(controller)



var picture9scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture9 .picture__part",
  triggerHook: ".5",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture9 .picture__part', {y: '120px'})
.addTo(controller)



var picture11scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture11 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture11 .picture__part', {y: '-194px', opacity: '1'})
.addTo(controller)



var picture13scene1 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture13 .picture__part--1",
  triggerHook: ".8",
  reverse: true,
  duration: '35%'
})
.setTween('.picture__main--picture13 .picture__part--1', {transform: 'translate(-50px, 130px) rotate(-90deg)'})
.addTo(controller)

var picture13scene2 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture13 .picture__part--2",
  triggerHook: ".8",
  reverse: true,
  duration: '35%'
})
.setTween('.picture__main--picture13 .picture__part--2', {transform: 'translate(0, 130px) rotate(57.61deg)'})
.addTo(controller)

var picture13scene3 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture13 .picture__part--3",
  triggerHook: ".8",
  reverse: true,
  duration: '35%'
})
.setTween('.picture__main--picture13 .picture__part--3', {transform: 'translate(30px, 120px) rotate(41.09deg)'})
.addTo(controller)


var picture13scene4 = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture13 .picture__part--4",
  triggerHook: ".8",
  reverse: true,
  duration: '35%'
})
.setTween('.picture__main--picture13 .picture__part--4', {transform: 'translate(45px, 130px) rotate(90deg)'})
.addTo(controller)


//var picture14scene1 = new ScrollMagic.Scene({
//  triggerElement: ".picture__main--picture14 .picture__part--2",
//  triggerHook: "onEnter",
//  reverse: true,
//  duration: '50%'
//})
//.setTween('.picture__main--picture14 .picture__part--2', {top: '298px', opacity: '1'})
//.addTo(controller)
//
//var picture14scene2 = new ScrollMagic.Scene({
//  triggerElement: ".picture__main--picture14 .picture__part--3",
//  triggerHook: "onEnter",
//  reverse: true,
//  duration: '50%'
//})
//.setTween('.picture__main--picture14 .picture__part--3', {top: '270px', opacity: '1'})
//.addTo(controller)


var picture15scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture15 .picture__part",
  triggerHook: ".7",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture15 .picture__part', {y: '-86px'})
.addTo(controller)



var picture16scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture16 .picture__part",
  triggerHook: ".7",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture16 .picture__part', {y: '-164px', opacity: '1'})
.addTo(controller)


var picture18scene = new ScrollMagic.Scene({
  triggerElement: ".picture__main--picture18 .picture__part",
  triggerHook: "onEnter",
  reverse: true,
  duration: '50%'
})
.setTween('.picture__main--picture18 .picture__part', {y: '-50px'})
.addTo(controller)
